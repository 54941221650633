/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import "./src/styles/global.css"
import BasketProvider from "./src/utils/BasketContext"

// import { registerLinkResolver } from "gatsby-source-prismic-graphql"
// import { linkResolver } from './src/prismic-configuration.notused';

// registerLinkResolver(linkResolver);

export const wrapRootElement = ({ element }) => (
  <BasketProvider>{element}</BasketProvider>
)

export const onClientEntry = () => {
  window.onload = () => {
    const script = document.createElement('script')
    script.src = 'https://www.instagram.com/embed.js'
    script.async = true
    document.body.appendChild(script)
  }
}
// The DOMContentLoaded event fires when the initial HTML document has been completely loaded and parsed, without waiting for stylesheets, images, and subframes to finish loading.
//   window.addEventListener('DOMContentLoaded', (event) => {
//   });

export const shouldUpdateScroll = ({ pathname, routerProps }) => {
  let route = pathname || routerProps.location.pathname

  if (route === `/contact/success/` || route === `/checkout/success/`) {
    window.scrollTo(0, 0)
  }
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This website has been updated. ` +
      `Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}