import React from "react"

const BasketContext = React.createContext()

const DEFAULT_SHIPPING_PRICE = 15

class BasketProvider extends React.Component {
  state = {
    shipping: {
      address:
        typeof window !== "undefined"
          ? JSON.parse(window.localStorage.getItem("address")) || {}
          : {},
      price: 0,
      priceId: process.env.GATSBY_STRIPE_SHIPPING_PRICE_ID,
    },
    basketItems:
      typeof window !== "undefined"
        ? JSON.parse(window.localStorage.getItem("basket_items")) || []
        : [],
    setBasketItem: this.setBasketItem.bind(this),
    setShipping: this.setShipping.bind(this),
    removeBasketItem: this.removeBasketItem.bind(this),
    findBasketItem: this.findBasketItem.bind(this),
    clearBasket: this.clearBasket.bind(this),
  }

  componentDidMount() {
    const { shipping } = this.state
    this.setState({
      shipping: {
        ...shipping,
        // * Free for Spain
        price:
          shipping.address.country && shipping.address.country.ISO === "ES"
            ? 0
            : DEFAULT_SHIPPING_PRICE,
      },
    })
  }

  setShipping(address = {}, price = DEFAULT_SHIPPING_PRICE) {
    const newAddress = {
      ...this.state.shipping.address,
      ...address,
    }
    this.setState(
      {
        shipping: {
          ...this.state.shipping,
          address: newAddress,
          // * Free for Spain
          price:
            newAddress.country && newAddress.country.ISO === "ES" ? 0 : price,
        },
      },
      () => this.setShippingCallback(newAddress)
    )
  }

  /**
   * * Can be called either for adding or removing items from the basket
   * @param {*} item
   */
  setBasketItem(item) {
    // * get stripe product details only for the selected size - size has to match XS, S, M, L or XL
    const productItem = item.size ? updateItemStripeProductDetails(item) : item

    const updatedProduct = this.updateItemQuantity(productItem)

    // * replace item from state.basketItems array if it already exists
    const basketItems = this.replaceItemInBasket(updatedProduct)
    this.setState(
      {
        basketItems,
      },
      () => this.setBasketItemCallback(basketItems)
    )
  }

  /**
   *
   * @param {Object} item
   * @returns {Array} udpated basketItems
   */
  replaceItemInBasket(item) {
    if (!this.state.basketItems.length) {
      // * first item to be added
      return [item]
    }

    let index = null
    const basketItems = this.state.basketItems.map((basketItem, i) => {
      if (
        basketItem._stripe_product_details.id ===
        item._stripe_product_details.id
      ) {
        // * save index for replacing
        index = i
        return null
      } else {
        return basketItem
      }
    })

    // * If item already exists in basket, replace it in the array
    // * If item is not in the basket, add it at the end of the array
    return index !== null
      ? [...basketItems.slice(0, index), item, ...basketItems.slice(index + 1)]
      : [...basketItems, item]
  }

  /**
   * * For removing item all together, regardless of quantity
   * @param {*} productItem
   */
  removeBasketItem(productItem) {
    const currentBasketItems = this.state.basketItems
    const basketItems = currentBasketItems.filter(
      basketItem =>
        basketItem._stripe_product_details.id !==
        productItem._stripe_product_details.id
    )

    if (basketItems.length === currentBasketItems.length) {
      console.warn(
        `⚠️ BasketContext: removeBasketItem -> item was not found in the shopping basket`,
        productItem
      )
      return false
    }

    this.setState(
      {
        basketItems,
      },
      () => this.setBasketItemCallback(basketItems)
    )
  }

  setBasketItemCallback(basketItems) {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("basket_items", JSON.stringify(basketItems))
      return true
    }
    return false
  }
  setShippingCallback(address) {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("address", JSON.stringify(address))
      return true
    }
    return false
  }

  updateItemQuantity(item) {
    const basketItem = this.findBasketItem(item)
    if (!basketItem) {
      return item
    }

    // * if item already exists, ammend its quantity
    // ! can be negative - will be removed by replaceBasketItem if less than 1
    basketItem.quantity += item.quantity
    return basketItem
  }

  findBasketItem(item) {
    // ! priced individual items have different title but the same top item uid - check against size also
    return this.state.basketItems.find(
      currentItem =>
        currentItem._stripe_product_details.id ===
        item._stripe_product_details.id
    )
    /* if (!!item.size) {
      if (currentItem.size) {
        return (currentItem.title === item.title) && (currentItem.size.label === item.size.label)
      } else {
        // ? item to be checked has a size, current basket item doesn't - return false as it's not a match
        return false
      }
    } else {
      return (currentItem.title === item.title)
    } */
  }

  clearBasket() {
    if (this.state.basketItems && this.state.basketItems.length) {
      const basketItems = []
      this.setState(
        {
          basketItems,
        },
        () => this.setBasketItemCallback(basketItems)
      )
    }
  }

  render() {
    return (
      <BasketContext.Provider value={this.state}>
        {this.props.children}
      </BasketContext.Provider>
    )
  }
}

const updateItemStripeProductDetails = item => {
  if (
    !item._stripe_product_details ||
    (!Array.isArray(item._stripe_product_details) &&
      typeof item._stripe_product_details === "object")
  ) {
    return item
  }

  const stripeProductDetails = item._stripe_product_details.find(
    details =>
      details.metadata.size.toUpperCase() === item.size.label.toUpperCase()
  )
  delete item._stripe_product_details
  // ! convert item's _stripe_product_details field from an Array to an Object
  return {
    ...item,
    _stripe_product_details: stripeProductDetails,
  }
}

export { BasketContext }

export default BasketProvider
